<template>
  <div class="services">

    <hr class="section-divider"/>

    <div class="services-section">
      <h2>SERVICES</h2>
      <div class="cards-section">
        <div class="card">
          <h3 class="card-title">Research & Insights</h3>
            <p class="service-description">
                User/customer research <br/>
                Market research <br/>
                Product/service ideation <br/>
                Service blueprinting <br/>
                Product/service roadmapping</p>
        </div>
        <div class="card second-card">
          <h3 class="card-title">Design & Strategy</h3>
          <p class="service-description">
            UI/UX design <br/>
            Service design <br/>
            Experience strategy <br/>
            Brand identity & strategy <br/>
            Art direction</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesHighlights',
};
</script>

<style scoped>

.services{
    background-image: url(@/assets/service-bg.png) !important;
    background-size: cover;
    height: 674px;
}

.cards-section {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;
}

.card {
  width: 40%;
  padding: 1rem 4rem;
  border-radius: 8px;
  text-align: center;
}

.card-title {
  font-size: 34px;
  line-height: 42px;
  color: #4392F1;
  font-weight: 700;
  font-family: 'Merriweather', serif;
}

.service-description {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #AFA98D;
  text-align: center;
}

.section-divider {
  margin: 0;
  border: 0;
  border-top: 2px solid #313131;
}

.services-section h2 {
  font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 6rem;
    font-family: 'Inter', serif;
    color: #AFA98D;
    letter-spacing: 15px; 
}


/* Responsive design for mobile view */
@media screen and (max-width: 767px) {
  .h1, .p {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

   .cards-section {
    flex-direction: column;
    padding: 2rem;
    margin-top: 1rem;
  }

  .card {
    width: 90%;
    padding: 20px;
    }

  .card.profile-1 {
    margin-top: 1rem;
  }

   .card.profile-2 {
    margin-top: 8rem;
    text-align: right;
  }

  .card-description {
    text-align: left;
  }

  .card-title{
    font-size: 29px;
    width: 100%;
  }

  .service-description {
    font-size: 14px;
    line-height: 31px;
    margin-top: 4rem;
  }

  .services{
      background-image: url(@/assets/services-bg-mobile.png) !important;
      background-size: cover;
      height: 1000px;
    }

  .services-section h2 {
    margin-top: 3rem;
    padding-left: 23px;
  }

  .second-card {
    margin-top: 6rem;
  }

}

/* Responsive design for tab view */
@media (min-width: 768px) and (max-width: 1024px) {
    .h1, .p {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

   .cards-section {
    flex-direction: column;
    padding: 2rem;
    margin-top: 1rem;
  }

  .card {
    width: 90%;
    padding: 20px;
    }

  .card.profile-1 {
    margin-top: 1rem;
  }

   .card.profile-2 {
    margin-top: 8rem;
    text-align: right;
  }

  .card-description {
    font-size: 18px;
    line-height: 40px;
  }

  .card-title{
    font-size: 29px;
    width: 100%;
  }

  .service-description {
    font-size: 18px;
    line-height: 40px;
    margin-top: 4rem;
  }

  .services{
      background-image: url(@/assets/services-bg-mobile.png) !important;
      background-size: cover;
      height: 1350px;
    }

  .services-section h2 {
    margin-top: 3rem;
  }

  .second-card {
    margin-top: 6rem;
  }

}

</style>
