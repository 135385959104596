<template>
  <div class="profile-highlights">
    <!-- Section 1: 2 Cards -->
    <div class="cards-section">
      <div class="card profile-1">
        <img src="@/assets/Icon1.png" alt="Icon 1" class="card-icon1"/>
        <h3 class="card-title">Strategic + well rounded</h3>
        <p class="card-description">Leveraging expertise in business, service design and brand strategy, I ensure your UI/UX designs are shaped by multiple perspectives, delivering maximum impact while aligning seamlessly with broader business goals and requirements.</p>
      </div>
      <div class="card profile-2">
        <img src="@/assets/Icon2.png" alt="Icon 2" class="card-icon2"/>
        <h3 class="card-title">Empathetic + user focused</h3>
        <p class="card-description">With extensive experience in user research and
        a strong foundation in TU Delft MSc methodologies,
        I prioritize the user at every stage
        of the design process, creating delightful experiences that foster customer loyalty.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileHighlights',
};
</script>

<style scoped>

.profile-highlights{
  background-image: url(@/assets/PF-bg.png);
  background-size: cover;
  height: 835px;
}

.cards-section {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.card {
  width: 28%;
  padding: 1rem 4rem;
  text-align: center;
}

.card-icon1 {
  width: 74px; 
  height: 94px;
}

.card-icon2{
  width: 59px;
  height: 59px;
}

.card-title {
  font-size: 34px;
  line-height: 42px;
  color: #4392F1;
  font-weight: 700;
  font-family: 'Merriweather', serif;
}

.card-description {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #AFA98D;
  text-align: left;
}

.card.profile-1{
    margin-top: 6rem;
}

.card.profile-2 {
    margin-top: 16rem;
}


/* Responsive design for mobile view */
@media screen and (max-width: 767px) {
  .h1, .p {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

   .cards-section {
    flex-direction: column;
    padding: 2rem;
  }

  .card {
    width: 90%;
    padding: 20px;
    text-align: left;
    }

  .card.profile-1 {
    margin-top: 1rem;
  }

   .card.profile-2 {
    margin-top: 8rem;
    text-align: right;
  }

  .card-title,
  .card-description {
    text-align: left;
  }

  .card-title{
    font-size: 29px;
    width: 70%;
  }

  .card-description {
    font-size: 14px;
    line-height: 31px;
  }


  .profile-highlights{
      background-image: url(@/assets/PF-bg-mobile.png) !important;
      background-size: cover;
      height: 1500px;
    }

}

/* Responsive design for tab view */
@media (min-width: 768px) and (max-width: 1024px) {
 .h1, .p {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

   .cards-section {
    flex-direction: column;
    padding: 2rem;
  }

  .card {
    width: 90%;
    padding: 20px;
    text-align: left;
    }

  .card.profile-1 {
    margin-top: 6rem;
  }

   .card.profile-2 {
    margin-top: 8rem;
    text-align: right;
  }

  .card-title,
  .card-description {
    text-align: left;
  }

  .card-title{
    font-size: 29px;
    width: 70%;
  }

  .card-description {
    font-size: 18px;
    line-height: 40px;
  }


  .profile-highlights{
      background-image: url(@/assets/PF-bg-mobile.png) !important;
      background-size: cover;
      height: 1450px;
    }
}
</style>
