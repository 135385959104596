<template>
  <hr class="section-divider"/>
  <footer class="footer">
    <a href="mailto:contact@azhimfirdaus.com" class="contact-email">CONTACT</a>
    <a href="https://www.linkedin.com/in/azhimfirdaus/" target="_blank" rel="noopener noreferrer">
      <img src="@/assets/linkedin-icon.png" alt="LinkedIn Icon" class="linkedin-icon" />
    </a>
    <p class="footer-text">ALL RIGHTS RESERVED © AZHIM FIRDAUS 2024</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterApp',
};
</script>

<style scoped>
.footer{
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.4em;
  color: #AFA98D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 3rem;
}

.footer .linkedin-icon {
  width: 37px;
  height: 37px;
  align-self: center; 
}

.footer .contact-email, .footer .footer-text {
  flex: 1;
}

.footer .footer-text {
  text-align: right;
}

.section-divider {
  margin: 0;
  border: 0;
  border-top: 2px solid #313131;
}

.contact-email {
    text-decoration: underline;
    color: #AFA98D;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    letter-spacing: 5px;
    text-align: left;
}

/* Responsive design for mobile view */
@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column-reverse;
    text-align: center;
    padding: 2rem 5.2rem;
    gap: 2rem;
  }

  .footer-text {
    margin-top: 1rem;
  }

  .footer .linkedin-icon {
    width: 31px;
    height: 31px;
    align-self: center; 
  }

  .footer .contact-email  {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .footer .footer-text {
    text-align: center;
  }
}

/* Responsive design for tablet view */
@media (min-width: 768px) and (max-width: 1024px) {
  .footer {
    flex-direction: column-reverse;
    text-align: center;
    padding: 2rem 5.2rem;
    gap: 2rem;
  }

  .footer-text {
    text-align: center;
    margin-top: 1rem;
  }

  .footer .contact-email  {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

}


</style>
