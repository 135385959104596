<template>
  <section class="hero">
    <div class="hero-image">
        <img src="@/assets/futuristic-hero.png" alt="futuristic" class="hero-img"/>
    </div>
    <div class="hero-text">
        <h1><span style="color: #4392F1;">UI/UX Designer</span> with Business, Service Design & Brand Strategy expertise</h1>
        <p>8+ years professional experience, TU Delft MSc.</p>
        <p>Based in Amsterdam</p>
        <p>Roots in Jakarta</p>
    </div>
     <hr class="section-divider"/>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
};
</script>

<style scoped>
/* Full-screen Hero image */
.hero {
  width: 100%;
  height: auto;
  background-color: #252525;
}

/* Hero image styling */
.hero-img {
    width: 100%;
    display: block;
    object-fit: cover;
}

/* Layout for HeroSection */
.HeroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Text styling */
.hero-text {
    text-align: left;
    color: white;
    font-family: 'Merriweather', serif;
    padding: 4rem;
    width: 100%;
    margin-top: 2rem;
}

/* Title and paragraph styling */
.hero-text h1 {
  font-size: 27px;
  margin-bottom: 15px;
  color: #AFA98D;
  line-height: 34px;
  margin: 0;
  font-weight: normal;
  padding-bottom: 12px;
}

.hero-text p {
  font-size: 27px;
  line-height: 34px;
  color: #AFA98D;
  margin: 0;
  padding-bottom: 12px;
}

.section-divider {
  margin: 0;
  border: 0;
  border-top: 2px solid #313131;
}

/* Responsive design for mobile view */
@media screen and (max-width: 767px) {
  .hero-text h1, .hero-text p {
    font-size: 23px; 
    line-height: 41px;
    margin-bottom: 3rem;
  }

  .hero-text {
    width: auto;
    padding: 2.5rem;
  }

  .hero-img {
    min-height: 250px;
    object-position: 11% center;
    object-fit: cover;
  }
}

/* Responsive design for tab view */
@media (min-width: 768px) and (max-width: 1024px) {
  .hero-text h1, .hero-text p {
    font-size: 23px; 
    line-height: 38px;
    margin-bottom: 3rem;
  }

  .hero-text {
    width: auto;
  }

  .hero-img {
    min-height: 350px;
  }
}
</style>
