<template>
<nav class="navbar">
  <div class="navbar-left">
    <h1 class="brand-name">Azhim Firdaus</h1>
  </div>
  <div class="navbar-right">
      <ul class="menu">
        <li><a href="https://miro.com/app/board/uXjVM_yRgPU=/" target="_blank" rel="noopener noreferrer">Portfolio</a></li>
        <li><a href="https://www.linkedin.com/in/azhimfirdaus/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        <li><a href="mailto:contact@azhimfirdaus.com">Contact</a></li>
      </ul>
    </div>
  <div class="hamburger-menu" @click="toggleMobileMenu">
    <img src="@/assets/hamburger-menu.png" alt="Hamburger Menu" class="hamburger-icon" />
  </div>
  <ul class="menu" :class="{ 'mobile-menu-open': mobileMenuOpen }">
    <li class="first-menu"><a href="https://miro.com/app/board/uXjVM_yRgPU=/" target="_blank" rel="noopener noreferrer">Portfolio</a></li>
    <li><a href="https://www.linkedin.com/in/azhimfirdaus/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
    <li><a href="mailto:contact@azhimfirdaus.com">Contact</a></li>
  </ul>
</nav>


</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      mobileMenuOpen: false
    };
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      if (this.mobileMenuOpen) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Durer';
  src: url('@/assets/fonts/Durer.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* General styles */
.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #252525;
  color: #AFA98D;
  font-family: 'Inter', sans-serif;
  padding: 1rem 3rem;
  align-items: center;
  z-index: 100;
}

.navbar-left .brand-name {
  font-family: 'Durer', sans-serif;
  font-size: 43px;
  color: #AFA98D;
  font-weight: 400;
}

.navbar-right .menu {
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

.navbar-right .menu li a {
  color: #AFA98D;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
  font-size: 21px;
  line-height: 25px;
  font-weight: 400;
  position: relative;
}

.navbar-right .menu li a:hover {
  color: #4392F1;
  text-decoration: none;
}

/* Underline effect */
.navbar-right .menu li a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4392F1;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar-right .menu li a:hover::after {
  transform: scaleX(1);
}

.menu,
.hamburger-menu {
  display: none;
}

body.menu-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.mobile-menu-open {
  display: block;
  width: 100%;
  height: 100vh; 
  background-color: #252525;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 1rem 2rem;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .hamburger-menu {
    display: block;
    position: fixed;
    top: 0; 
    right: 0;
    width: 100%; 
    background-color: #252525;
    padding: 15px 20px 10px 20px;
    z-index: 10001;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
  }

  .hamburger-menu img {
    width: 19px;
    height: auto;
  }

  .navbar-left {
    position: absolute;
    margin-top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    color: #AFA98D;
    width: 300px;
  }

  .navbar-right {
    display: none;
  }

  .menu li a {
    color: #AFA98D;
    text-decoration: underline;
    font-family: 'Inter', sans-serif;
    font-size: 39px;
    line-height: 48px;
    font-weight: 400;
    position: relative;
  }

  li {
    margin-bottom: 3rem;
  }

  ul {
    list-style-type: none;
  }

  .navbar {
    padding: 20px;
    min-height: 165px;
  }

  .navbar-left .brand-name {
    margin: 0;
  }

  .first-menu {
    margin-top: 6rem;
  }
}

</style>
