<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
body {
  display: block;
  margin: 0 !important;
  overflow-x: hidden;
}
</style>
