<template>
  <div id="homepage">
    <NavBar/>
    <HeroSection/>
    <ProfileHighlights/>
    <ServicesHighlights/>
    <FooterApp/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import HeroSection from './components/HeroSection.vue'
import ProfileHighlights from './components/ProfileHighlights.vue'
import ServicesHighlights from './components/ServicesHighlights.vue'
import FooterApp from './components/FooterApp.vue'

export default {
  name: 'HomePage',
  components: {
    NavBar,
    HeroSection,
    ProfileHighlights,
    ServicesHighlights,
    FooterApp
  },
}
</script>

<style>

#homepage {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  background-color: #252525;
}
</style>
